import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import activityAPI from "../../../server/activity";

export default function activityTable(getAll) {
    
    const [activity, setActivity] = useState(null);
    const authHeader = useAuthHeader();
    
    useEffect(() => {
        !activity && APIRequest();
    }, [activity]);
    
    const APIRequest = () => {
        if (getAll) {
            console.log("getting all");
            
            activityAPI.listAll(authHeader()).then(res => {
                if (res.success === true) {
                    setActivity(res.data);
                } else {
                    alert(res);
                }
            });
            
        } else {
            console.log("getting mine");
            
            activityAPI.listMine(authHeader()).then(res => {
                if (res.success === true) {
                    setActivity(res.data);
                } else {
                    alert(res);
                }
            });
        }
    };
    
    const Author = ({name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="regular">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );
    
    function parseRows() {
        let rows = [];
        activity && activity.forEach(action => {
            let r = {
                user: <Author name={action.unlinkedUserName} email={action.unlinkedUserEmail} />,
                // action:  <MDTypography
                //                        variant="caption" color="text"
                //                        fontWeight="medium">{action.action}</MDTypography>,
                action: action.action,
                time: <MDTypography
                                    variant="caption" color="text"
                                    fontWeight="regular">{new Date(action.time).toLocaleString()}</MDTypography>,
            };
            rows.push(r);
        });
        
        return rows;
    }
    
    
    return {
        columns: [
            { Header: "User", accessor: "user", align: "left" },
            { Header: "Activity", accessor: "action", align: "left"},
            { Header: "Time", accessor: "time", align: "left", isSorted: true, isSortedDesc: "asce"}
        ],
        rows: parseRows()
    };
}
