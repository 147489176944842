
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import BasicLayout from "../components/BasicLayout";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import MDSnackbar from "../../../components/MDSnackbar";
import authAPI from "../../../server/auth";

function Cover() {
    const [email, setEmail] = useState(null);
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    
    const handleClick = (e) => {
        e.preventDefault();
        
        if (email !== ""){
            authAPI.restPassword(email).then(r => {
                setErrMessage(r.message)
                openErrorSB()
            })
        }
        
        
    }
    
    
    const renderErrorSB = (
        <MDSnackbar
            color="warning"
            icon="warning"
            title={errMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
            dateTime={""} />
    );
    
    
    return (
        <BasicLayout image={bgImage}>'
            {renderErrorSB}
            
            <Grid item xs={11} sm={9} md={6} lg={6} xl={4} my={10}>
                <MDBox mt={10}>
                    <Card>
                        <MDBox
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="success"
                            mx={2}
                            mt={-3}
                            py={2}
                            mb={1}
                            textAlign="center"
                        >
                            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                                Reset Password
                            </MDTypography>
                            <MDTypography display="block" variant="button" color="white" my={1} px={2}>
                                Enter your email you used to create account. We will send you the link to change
                                password.
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form" onSubmit={handleClick}>
                                <MDBox mb={4}>
                                    <MDInput type="email" label="Email" variant="standard" fullWidth
                                             onChange={e => {setEmail(e.target.value)}} required
                                    />
                                </MDBox>
                                <MDBox mt={6} mb={1}>
                                    <MDButton variant="gradient" color="info" fullWidth type={"submit"}>
                                        reset
                                    </MDButton>
                                </MDBox>
                                
                                <MDBox mt={3} mb={1} textAlign="center">
                                    <MDTypography variant="button" color="text">
                                        Remember your password?{" "}
                                        <MDTypography
                                            component={Link}
                                            to="/authentication/sign-in"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                            
                                        >
                                            Login Here
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                            
                            
                            </MDBox>
                        </MDBox>
                    </Card>
                </MDBox>
            </Grid>
        
        
        </BasicLayout>
    );
}

export default Cover;
