import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import influencersAPI from "../../../server/influencers";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";

export default function data() {
    const [data, setData] = useState(null);
    const [openConfirmDel, setConfirmDel] = useState(false);
    const [delId, setDelId] = useState(null)
    
    const authHeader = useAuthHeader();
    const navigate = useNavigate();
    
    useEffect(() => {
        !data &&
        influencersAPI.listAll(authHeader()).then(res => {
            console.log(res);
            if (res.success === true) {
                setData(res.data);
            } else {
                alert(res);
            }
        });
    }, [data]);
    
    
    const handleViewClick = (item) => {
        console.log(item);
        const slug = item.title.replaceAll(" ", "-").toLowerCase()
        navigate(`/influencers/view/${slug}`, {
            state: {
                isEditing: true,
                influencer: item,
                title: item.title,
            }
        });
    };
    
    
    
    const handleOpenConfirmDel = () => {
        setConfirmDel(true);
    };
    const handleCloseConfirmDel = () => {
        setConfirmDel(false);
    };
    
    const handleDeleteEntry = (id, title) => {
        influencersAPI.delete(authHeader(), {_id: id, title: title}).then(res => {
            console.log(res);
            if (res.success === true) {
                //Forcing a reload
                setData(null);
                handleCloseConfirmDel()
            } else {
                alert(res);
            }
        });
    
    }
    
    const ConfirmDelDialogue = () => {
        return (
            <Dialog
                open={openConfirmDel}
                onClose={handleCloseConfirmDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Delete?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are going to delete this influencer's data from the system. Are you sure you want to proceed?
                        After this process, this influencer will no longer show on the website.
                        <br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDel}>Disagree</Button>
                    <Button onClick={()=>{
                        if (delId){
                            handleDeleteEntry(delId.id, delId.title)
                        }else{
                            handleCloseConfirmDel()
                        }
                        
                    }} color={"error"} >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    
    
    function parseRows() {
        let rows = [];
        data && data.forEach(inf => {
            let r = {
                title: <MDTypography display="block" variant="title"
                                     color="text"
                                     textTransform={"capitalize"}
                                     fontWeight="medium">{inf.title}</MDTypography>,
                
                subtitle: <MDTypography variant="caption">{inf.subtitle}</MDTypography>,
                
                added: <MDTypography variant="caption">{new Date(inf.createdAt).toLocaleString()}</MDTypography>,
                
                followers: <MDTypography variant="caption">{inf.followers}</MDTypography>,
                deposits: <MDTypography variant="caption">{inf.deposits}</MDTypography>,
                price: <MDTypography variant="caption">{inf.price}</MDTypography>,
                
                action: <>
                    <MDTypography component="a" style={{ cursor: "pointer"}}
                                  variant="caption" color="text"
                                  fontWeight="medium"
                                  onClick={() => {
                                      handleViewClick(inf);
                                  }}>View</MDTypography>
                    
                    <span>&nbsp;/</span>
                    
                    <MDTypography component="a" style={{ cursor: "pointer", paddingLeft: 6  }}
                                  variant="caption" color="text"
                                  fontWeight="medium"
                                  onClick={()=>{
                                      setDelId({id: inf._id, title: inf.title})
                                      handleOpenConfirmDel()
                                  }}>Delete</MDTypography>
                    
                    <ConfirmDelDialogue/>
                    
                </>
            };
            
            
            rows.push(r);
        });
        
        return rows;
    }
    
    return {
        columns: [
            { Header: "Title", accessor: "title", align: "left" },
            { Header: "Subtitle", accessor: "subtitle", align: "left" },
            { Header: "Date Added", accessor: "added", align: "left" },
            { Header: "Followers", accessor: "followers", align: "left" },
            { Header: "Deposits $", accessor: "deposits", align: "left" },
            { Header: "Price $", accessor: "price", align: "left" },
            { Header: "Action", accessor: "action", align: "right" }
        ],
        
        rows: parseRows()
    };
}
