import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";
import activityTable from "./data/activityTable";

function Tables() {
    const { columns, rows } = activityTable(1);
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                py={3}
                                px={2}
                            >
                                <MDTypography variant="h6" color="dark">
                                    Activity
                                </MDTypography>
                                <MDTypography variant="caption" color="dark">
                                    Here you can see the activity log of the platform. Activity is recorded live as the events happen.
                                    Feel free to refresh the page for the latest activity.
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    entriesPerPage={false}
                                    showTotalEntries={true}
                                    noEndBorder
                                    canSearch
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Tables;
