import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MDInput from "../../../components/MDInput";
import MDSnackbar from "../../../components/MDSnackbar";
import authAPI from "../../../server/auth";
import { useAuthHeader } from "react-auth-kit";

function PlatformSettings() {
    const authHeader = useAuthHeader();
    
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [successMessage, seTsuccessMessage] = useState("");
    const [successSB, setSuccessSB] = useState(false);
    
    const handlePasswordUpdate = () => {
        if (!currentPassword) {
            setErrMessage("Please Enter your current password");
            openSB();
        } else if (!newPass) {
            setErrMessage("Please Enter your new password");
            openSB();
        } else {
            authAPI.changePassword(authHeader(), currentPassword, newPass).then(r => {
                console.log(r);
                if (r.success){
                    seTsuccessMessage(r.message)
                    setShowNewPassword(false)
                }else{
                    setErrMessage(r.message);
                    openSB();
                }
            });
        }
        
    };
    
    
    const openSB = () => setErrorSB(true);
    const closeSB = () => setErrorSB(false);
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={errMessage}
            open={errorSB}
            onClose={closeSB}
            close={closeSB}
            bgWhite
            dateTime={""} />
    );
    
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <MDSnackbar
            color="success"
            icon="check"
            title="Success"
            content={successMessage}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
            dateTime={""} />
    );
    return (
        <Card sx={{ boxShadow: "none", width: "100%" }}>
            {renderErrorSB}
            {renderSuccessSB}
            <Box p={2}>
                <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
                    settings
                </Typography>
            </Box>
            <Box pt={1} pb={2} px={2} lineHeight={1.25}>
                <MDButton variant={"gradient"} color={"light"} size={"small"} onClick={() => {
                    setShowNewPassword(current => !current);
                }}>
                    Click here to change password
                </MDButton>
                
                {showNewPassword && <>
                    <Box mt={2} border={1} borderColor={"#fafafa"} borderRadius={3} padding={0.5}
                         display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box m={0.5} width={"50%"}>
                            <MDInput size={"small"} type={"password"} placeholder={"Current password"} fullWidth
                                     onChange={e => {
                                         setCurrentPassword(e.target.value);
                                     }} />
                        </Box>
                        <Box m={0.5} width={"50%"}>
                            <MDInput size={"small"} type={"password"} placeholder={"New password"} fullWidth
                                     onChange={e => {
                                         setNewPass(e.target.value);
                                     }} />
                        </Box>
                        <Box m={0.5}>
                            <MDButton size={"small"}
                                      fullWidth variant={"gradient"}
                                      color={"secondary"}
                                      onClick={handlePasswordUpdate}
                            >Apply</MDButton>
                        </Box>
                    </Box>
                </>}
            
            
            </Box>
        </Card>
    );
}

export default PlatformSettings;
