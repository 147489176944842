import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Images
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import articlesAPI from "../../server/articles";
import { useNavigate } from "react-router-dom";

export default function data() {
    
    const [data, setData] = useState(null);
    const authHeader = useAuthHeader();
    const navigate = useNavigate();
    
    useEffect(() => {
        !data &&
        articlesAPI.listAll(authHeader()).then(res => {
            console.log(res);
            if (res.success === true) {
                setData(res.data);
            } else {
                alert(res);
            }
        });
    }, [data]);
    
    
    const handleViewClick = (blog) => {
        console.log(blog);
        navigate(`/articles/edit/${blog.slug}`, {
            state: {
                isEditing: true,
                blog: blog,
                title: blog.title,
                slug: blog.slug
            }
        });
    };
    
    
    const Author = ({ name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="normal">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );
    
    function parseRows() {
        let rows = [];
        data && data.forEach(blog => {
            let r = {
                title: <MDTypography display="block" variant="title" color="text"
                                     fontWeight="medium">{blog.title}</MDTypography>,
                author: <Author name={blog.author.firstname + " " + blog.author.surname} email={blog.author.email} />,
                status: <MDBadge badgeContent={blog.status}
                                 color={blog.status === "published" ? "success" : "secondary"}
                                 variant="gradient" size="sm" />,
                
                created: <MDTypography variant="caption">{new Date(blog.createdAt).toLocaleString()}</MDTypography>,
                modified: <MDTypography variant="caption">{new Date(blog.lastModified).toLocaleString()}</MDTypography>,
                action: (
                    <MDTypography component="a" style={{ cursor: "pointer" }}
                                  variant="caption" color="text"
                                  fontWeight="medium"
                                  onClick={() => {
                                      handleViewClick(blog);
                                  }}>
                        View
                    </MDTypography>
                )
            };
            rows.push(r);
        });
        
        return rows;
    }
    
    
    return {
        columns: [
            { Header: "Title", accessor: "title", align: "left" },
            { Header: "Author", accessor: "author", align: "left" },
            { Header: "Status", accessor: "status", align: "left" },
            { Header: "Created at", accessor: "created", align: "left" },
            { Header: "Last Modified", accessor: "modified", align: "left" },
            { Header: "Action", accessor: "action", align: "center" }
        ],
        rows: parseRows()
    };
}
