const axios = require("axios");
import config from "../config";

const baseURL = config.apiBaseURL;


const authAPI = {
    login: (username, password) => {
        const config = {
            method: "post",
            url: `${baseURL}/users/login`,
            data: {
                email: username,
                password: password
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    verifyToken: (authtoken) => {
        const config = {
            method: "get",
            url: `${baseURL}/users/auth`,
            headers: {
                "Authorization": authtoken
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    register: (data) => {
        const config = {
            method: "post",
            url: `${baseURL}/users/register`,
            data: data
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    changePassword: (authtoken, current, newpass) => {
        const config = {
            method: "post",
            url: `${baseURL}/users/change-password`,
            headers: {
                "Authorization": authtoken
            }, data: {
                password: current,
                newpass: newpass
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    restPassword: (email) => {
        const config = {
            method: "post",
            url: `${baseURL}/users/reset-password`,
            data: {
                email: email
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    setNewPasswordAfterValidatingKey: (key, password) => {
        const config = {
            method: "post",
            url: `${baseURL}/users/set-password`,
            data: {
                key: key,
                pass: password
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    requestAccess: (token, data) => {
        const config = {
            method: "post",
            headers: {
                "Authorization": token,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            url: `${baseURL}/users/request-access`,
            data: data
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    getRequests: (token) => {
        const config = {
            method: "get",
            headers: {
                "Authorization": token
            },
            url: `${baseURL}/users/get-access-requests`
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
    },
    approveAccessRequest: (token, data) => {
        const config = {
            method: "post",
            headers: {
                "Authorization": token
            },
            url: `${baseURL}/users/approve-access-request`,
            data: data
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
    }
};

export default authAPI;
