import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import mediaAPI from "../../../server/media";

export default function mediaTableData() {
    const [data, setData] = useState(null);
    const [openConfirmDel, setConfirmDel] = useState(false);
    const [delId, setDelId] = useState(null);
    
    const authHeader = useAuthHeader();
    const navigate = useNavigate();
    
    useEffect(() => {
        !data &&
        mediaAPI.listAll(authHeader()).then(res => {
            console.log(res);
            if (res.success === true) {
                setData(res.data);
            } else {
                alert(res);
            }
        });
    }, [data]);
    
    
    const handleViewClick = (item) => {
        console.log(item);
        const slug = item.platform.replaceAll(" ", "-").toLowerCase();
        navigate(`/media/view/${slug}`, {
            state: {
                isEditing: true,
                media: item,
                platform: item.platform
            }
        });
    };
    
    const handleOpenConfirmDel = () => {
        setConfirmDel(true);
    };
    const handleCloseConfirmDel = () => {
        setConfirmDel(false);
    };
    
    const handleDeleteEntry = (id, platform) => {
        mediaAPI.delete(authHeader(), { id: id, platfrom: platform }).then(res => {
            console.log(res);
            if (res.success === true) {
                //Forcing a reload
                setData(null);
                handleCloseConfirmDel();
            } else {
                alert(res);
            }
        });
        
    };
    
    const ConfirmDelDialogue = () => {
        return (
            <Dialog
                // hideBackdrop
                open={openConfirmDel}
                onClose={handleCloseConfirmDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Delete?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are going to delete this media item. Are you sure?
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDel}>Cancel</Button>
                    <Button onClick={() => {
                        if (delId) {
                            handleDeleteEntry(delId.id, delId.platform);
                        } else {
                            handleCloseConfirmDel();
                        }
                        
                    }} color={"error"}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    
    
    function parseRows() {
        let rows = [];
        data && data.forEach(inf => {
            let r = {
                platform: <MDTypography display="block" variant="title"
                                        color="text"
                                        textTransform={"capitalize"}
                                        fontWeight="medium">{inf.platform}</MDTypography>,
                
                country: <MDTypography variant="caption">{inf.country}</MDTypography>,
                
                added: <MDTypography variant="caption">{new Date(inf.createdAt).toLocaleString()}</MDTypography>,
                
                traffic: <MDTypography variant="caption">{inf.traffic}</MDTypography>,
                price: <MDTypography variant="caption">{inf.price}</MDTypography>,
                
                action: <>
                    <MDTypography component="a" style={{ cursor: "pointer" }}
                                  variant="caption" color="text"
                                  fontWeight="medium"
                                  onClick={() => {
                                      handleViewClick(inf);
                                  }}>View</MDTypography>
                    
                    <span>&nbsp;/</span>
                    
                    <MDTypography component="a" style={{ cursor: "pointer", paddingLeft: 6 }}
                                  variant="caption" color="text"
                                  fontWeight="medium"
                                  onClick={() => {
                                      setDelId({ id: inf._id, title: inf.title });
                                      handleOpenConfirmDel();
                                  }}>Delete</MDTypography>
                    
                    <ConfirmDelDialogue />
                
                </>
            };
            
            
            rows.push(r);
        });
        
        return rows;
    }
    
    return {
        columns: [
            { Header: "Platform", accessor: "platform", align: "left" },
            { Header: "Country", accessor: "country", align: "left" },
            { Header: "Date Added", accessor: "added", align: "left" },
            { Header: "Traffic", accessor: "traffic", align: "left" },
            { Header: "Price $", accessor: "price", align: "left" },
            { Header: "Action", accessor: "action", align: "right" }
        ],
        
        rows: parseRows()
    };
}
