/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.
 
 Once you add a new route on this file it will be visible automatically on
 the Sidenav.
 
 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */


/***
 Code -1: Allow to all users
 Code 99: Allow to managers only
 */


import Dashboard from "layouts/dashboard/index";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Icon from "@mui/material/Icon";
import Users from "./layouts/users";
import Articles from "./layouts/articles";
import Influencers from "./layouts/influencers";
import Media from "./layouts/media";
import Activity from "./layouts/activity";
import CreateNew from "./layouts/articles/create-new";
import Options from "./layouts/options";
import SingleInfluencer from "./layouts/influencers/single-influencer";
import SingleMedia from "./layouts/media/single-media";
import ResetPassword from "./layouts/authentication/resetPassword";
import NewPassword from "./layouts/authentication/newPassword";

const routes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        code: "-1",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        secure: true,
        component: <Dashboard />
    },
    {
        type: "collapse",
        name: "Users",
        key: "users",
        code: "99",
        secure: true,
        icon: <Icon fontSize="small">person</Icon>,
        route: "/users",
        component: <Users />
    },
    {
        type: "title",
        title: "Content Creation",
        key: "cc",
        secure: true
    },
    {
        type: "collapse",
        name: "Articles",
        key: "articles",
        code: "03",
        secure: true,
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/articles",
        collapse: [
            {
                type: "collapse",
                name: "All Articles",
                key: "articles",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/articles",
                component: <Articles />
            },
            {
                type: "collapse",
                name: "Create new article",
                key: "articles",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/articles/create-new/:id",
                component: <CreateNew />
            },
            {
                type: "collapse",
                name: "Edit / View",
                key: "articles",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/articles/edit/:id",
                component: <CreateNew />
            }
        ]
    },
    {
        type: "collapse",
        name: "Influencers",
        key: "influencers",
        code: "01",
        secure: true,
        icon: <Icon fontSize="small">recent_actors</Icon>,
        route: "/influencers",
        collapse: [
            {
                type: "collapse",
                name: "All Articles",
                key: "influencers",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/influencers",
                component: <Influencers />
            },
            {
                type: "collapse",
                name: "Create new article",
                key: "influencers",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/influencers/view/:id",
                component: <SingleInfluencer />
            },
            {
                type: "collapse",
                name: "Create new article",
                key: "influencers",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/influencers/create",
                component: <SingleInfluencer />
            }
        ]
    },
    {
        type: "collapse",
        name: "Media",
        key: "media",
        code: "02",
        secure: true,
        icon: <Icon fontSize="small">perm_media</Icon>,
        route: "/media",
        collapse: [
            {
                type: "collapse",
                name: "All Articles",
                key: "media",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/media",
                component: <Media />
            },
            {
                type: "collapse",
                name: "All Articles",
                key: "media",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/media/create",
                component: <SingleMedia />
            },
            {
                type: "collapse",
                name: "Create new article",
                key: "media",
                secure: true,
                icon: <Icon fontSize="small">recent_actors</Icon>,
                route: "/media/view/:id",
                component: <SingleMedia />
            },
        ]
    },
    {
        type: "title",
        title: "Others",
        key: "otr",
        secure: true
    },
    {
        type: "collapse",
        name: "Activity",
        key: "activity",
        secure: true,
        code: "-1",
        icon: <Icon fontSize="small">segment</Icon>,
        route: "/activity",
        component: <Activity />
    },
    {
        type: "collapse",
        name: "Options",
        key: "options",
        secure: true,
        code: "99",
        icon: <Icon fontSize="small">segment</Icon>,
        route: "/options",
        component: <Options />
    },
    {
        type: "collapse",
        name: "Sign In",
        key: "sign-in",
        code: "-1",
        icon: <Icon fontSize="small">login</Icon>,
        route: "/authentication/sign-in",
        component: <SignIn />
    },
    {
        type: "collapse",
        name: "Sign Up",
        key: "sign-up",
        code: "-1",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/authentication/sign-up",
        component: <SignUp />
    },
    {
        type: "collapse",
        name: "Sign Up",
        key: "sign-up",
        code: "-1",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/authentication/reset-Password",
        component: <ResetPassword />
    },
    {
        type: "collapse",
        name: "Sign Up",
        key: "sign-up",
        code: "-1",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/authentication/set-new-password",
        component: <NewPassword />
    }

];

export default routes;
