/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";
// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import authAPI from "../../../server/auth";
import MDSnackbar from "../../../components/MDSnackbar";


function Basic() {
    const [rememberMe, setRememberMe] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    
    const navigate = useNavigate();
    const signIn = useSignIn();
    const isAuthenticated = useIsAuthenticated();
    
    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    
    const handleSubmit = () => {
        authAPI.login(email, password).then(r => {
            if (r.loginSuccess === true) {
                
                authAPI.verifyToken(r.authToken).then(ath => {
                    
                    if (ath.isAuth === true) {
                        handleSignIn(r, ath);
                    } else {
                        setErrMessage("Unable to get auth token");
                        openErrorSB();
                    }
                });
                
            } else {
                setErrMessage(r.message);
                openErrorSB();
            }
            
        });
    };
    
    
    const handleSignIn = (r, ath) => {
        if (signIn({
            token: r.authToken,
            expiresIn: r.authExp,
            tokenType: "Bearer",
            authState: ath
        })) {
            navigate("/secure");
        } else {
            openErrorSB();
        }
    };
    
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Login Failed"
            content={errMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
            dateTime={""} />
    );
    
    if (isAuthenticated()) {
        return (
            <Navigate to={"/dashboard"} replace />
        );
        
    } else {
        return (
            <BasicLayout image={bgImage}>
                {renderErrorSB}
                <Grid item xs={11} sm={9} md={6} lg={6} xl={4} my={10}>
                    <Card>
                        <MDBox
                            variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3}
                            p={2} mb={1} textAlign="center"
                        >
                            <MDTypography variant="h4" fontWeight="medium" color="white" my={1}>
                                Sign in
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                
                                <MDBox mb={2}>
                                    <MDInput type="email" label="Email" fullWidth
                                             onChange={e => {
                                                 setEmail(e.target.value);
                                             }}
                                    />
                                
                                </MDBox>
                                <MDBox mb={2}>
                                    <MDInput type="password" label="Password" fullWidth
                                             onChange={e => {
                                                 setPassword(e.target.value);
                                             }}
                                    />
                                
                                </MDBox>
                                <MDBox display="flex" alignItems="center" ml={-1}>
                                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                                    <MDTypography
                                        variant="button" fontWeight="regular" color="text"
                                        onClick={handleSetRememberMe}
                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                    >
                                        &nbsp;&nbsp;Remember me
                                    </MDTypography>
                                </MDBox>
                                
                                
                                <MDBox mt={4} mb={1}>
                                    <MDButton variant="gradient" color="info" fullWidth
                                              onClick={handleSubmit}>
                                        sign in
                                    </MDButton>
                                </MDBox>
                                <MDBox mt={2} mb={1} textAlign="center">
                                    <MDTypography variant="button" color="text">
                                        Forgot your password?{" "}
                                        <MDTypography
                                            component={Link}
                                            to="/authentication/reset-password"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                        >
                                            Reset Password
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                                
                                <MDBox mt={3} mb={1} textAlign="center">
                                    <MDTypography variant="button" color="text">
                                        Don&apos;t have an account?{" "}
                                        <MDTypography
                                            component={Link}
                                            to="/authentication/sign-up"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                        >
                                            Sign up
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                                
                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid>
            </BasicLayout>
        );
    }
    
}

export default Basic;
