import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';

import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
//
import { AuthProvider } from "react-auth-kit";


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <>
        <AuthProvider authType={"cookie"}
                      authName={"_auth"}
                      cookieDomain={window.location.hostname}
                      cookieSecure={window.location.protocol === "https:"}>
    
            <BrowserRouter>
                <MaterialUIControllerProvider>
                    <App />
                </MaterialUIControllerProvider>
            </BrowserRouter>
        </AuthProvider>
    </>
);
