const axios = require("axios");
import config from "../config";
const baseURL = config.apiBaseURL;

const articlesAPI = {
    listAll: (token) => {
        const config = {
            method: "get",
            url: `${baseURL}/blog/list`,
            headers: {
                "Authorization": token
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    getCategories: (token) => {
        const config = {
            method: "get",
            url: `${baseURL}/blog/categories/list`,
            headers: {
                "Authorization": token
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    createPost: (token, postData) => {
        
        const config = {
            method: "post",
            url: `${baseURL}/blog/create-new`,
            headers: {
                "Authorization": token
            },
            data: postData
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    updatePost: (token, postData) => {
        
        const config = {
            method: "post",
            url: `${baseURL}/blog/update-post`,
            headers: {
                "Authorization": token
            },
            data: postData
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    uploadImage: (token, blobInfo, progress) => new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open("POST", `${baseURL}/blog/upload-image`);
        xhr.setRequestHeader("Authorization", token);
        xhr.upload.onprogress = (e) => {
            progress(e.loaded / e.total * 100);
        };
        xhr.onload = () => {
            if (xhr.status === 403) {
                reject({ message: "HTTP Error: " + xhr.status, remove: true });
                return;
            }
            if (xhr.status < 200 || xhr.status >= 300) {
                reject("HTTP Error: " + xhr.status);
                return;
            }
            const json = JSON.parse(xhr.responseText);
            resolve(json);
        };
        xhr.onerror = () => {
            reject("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
        };
        const formData = new FormData();
        formData.append("image", blobInfo.blob(), blobInfo.filename());
        xhr.send(formData);
    }),
    
    uploadFeaturedImage: (token, image) => {
        const formData = new FormData();
        formData.append('image', image);
        const config = {
            method: "post",
            url: `${baseURL}/blog/upload-image`,
            headers: {
                "Authorization": token
            },
            data: formData
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
    }
};


export default articlesAPI;