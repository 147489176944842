// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ProfileInfoCard from "./ProfileInfoCard";
import config from "../../config";


// Overview page components
import backgroundImage from "../../assets/images/bg-profile.jpeg";
import placeholder from "../../assets/profile_placeholder.png";
import Card from "@mui/material/Card";
import MDAvatar from "../../components/MDAvatar";


import { useAuthHeader, useAuthUser } from "react-auth-kit";
import PlatformSettings from "./PlatformSettings";
import DataTable from "../../components/Tables/DataTable";
import activityTable from "../activity/data/activityTable";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import MDButton from "../../components/MDButton";
import authAPI from "../../server/auth";
import MDSnackbar from "../../components/MDSnackbar";
import { useState } from "react";

function Overview() {
    const authUser = useAuthUser();
    const authHeader = useAuthHeader();
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [successMessage, seTsuccessMessage] = useState("");
    const [successSB, setSuccessSB] = useState(false)
    const { columns, rows } = activityTable(0);
    
    const handleformSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const queryString = new URLSearchParams(new FormData(e.target)).toString()
        
        authAPI.requestAccess(authHeader(), queryString).then(r =>{
            if (r.success){
                seTsuccessMessage("Access Request Sent.")
                openSuccessSB()

            }else{
                setErrMessage("Failed to send access request.")
                openSB()
            }
        })

        
        
    };
    
    
    const openSB = () => setErrorSB(true);
    const closeSB = () => setErrorSB(false);
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={errMessage}
            open={errorSB}
            onClose={closeSB}
            close={closeSB}
            bgWhite
            dateTime={""} />
    );
    
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <MDSnackbar
            color="success"
            icon="check"
            title="Success"
            content={successMessage}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
            dateTime={""} />
    );
    
    
    return (
        <DashboardLayout>
            {renderErrorSB}
            {renderSuccessSB}
            <DashboardNavbar />
            <MDBox mb={2} />
            <MDBox position="relative" mb={5}>
                
                {/* Background cover */}
                <MDBox
                    display="flex" alignItems="center" position="relative" minHeight="18.75rem"
                    borderRadius="xl" sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.6),
                            rgba(gradients.info.state, 0.6)
                        )}, url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden"
                }}
                />
                
                <Card sx={{ position: "relative", mt: -8, mx: 3, py: 2, px: 2 }}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <MDAvatar
                                src={authUser().image !== "none" ? config.userImagePath + authUser().image : placeholder}
                                alt="profile-image"
                                size="xl"
                                shadow="sm" />
                        </Grid>
                        <Grid item>
                            <MDBox height="100%" mt={0.5} lineHeight={1}>
                                <MDTypography variant="h5" fontWeight="medium" textTransform={"uppercase"}>
                                    {authUser().firstname} {authUser().surname}
                                </MDTypography>
                                <MDTypography variant="button" color="text" fontWeight="regular"
                                              textTransform={"uppercase"}>
                                    {authUser().access_level}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                    </Grid>
                    <MDBox mt={5} mb={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                                <ProfileInfoCard
                                    title="profile information"
                                    description=""
                                    info={{
                                        "First Name": authUser().firstname,
                                        "Surname": authUser().surname,
                                        "Email Address": authUser().email
                                    }}
                                    shadow={false}
                                />
                                <Divider orientation="vertical" sx={{ mx: 0 }} />
                            </Grid>
                            
                            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                                <PlatformSettings />
                            
                            </Grid>
                            
                            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                                {authUser().access_level === "limited"
                                    && authUser().pagesallowed.length < 2
                                    && <>
                                        <Divider orientation="vertical" sx={{ mx: 0 }} />
                                        
                                        <Box p={2} component="form" role="form" onSubmit={handleformSubmit}>
                                            <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
                                                Request Access
                                            </Typography>
                                            {!authUser().pagesallowed.includes("01") &&
                                                <MDBox mb={1}>
                                                    <Checkbox name={"pagesallowed"} value={"01"} />
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                    >
                                                        &nbsp;&nbsp; Request Influencers Access
                                                    </MDTypography>
                                                </MDBox>
                                            }
                                            {!authUser().pagesallowed.includes("02") &&
                                                <MDBox mb={1}>
                                                    <Checkbox name={"pagesallowed"} value={"02"} />
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                    >
                                                        &nbsp;&nbsp; Request Media Access
                                                    </MDTypography>
                                                </MDBox>
                                            }
                                            {!authUser().pagesallowed.includes("03") &&
                                                <MDBox mb={1}>
                                                    <Checkbox name={"pagesallowed"} value={"03"} />
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                    >
                                                        &nbsp;&nbsp; Request Articles Access
                                                    </MDTypography>
                                                </MDBox>
                                            }
                                            
                                            <MDButton type={"submit"} variant={"gradient"} size={"small"}
                                                      color={"secondary"}>Send
                                                Request</MDButton>
                                        </Box>
                                    </>
                                }
                            
                            </Grid>
                        </Grid>
                    </MDBox>
                </Card>
            
            </MDBox>
            
            <MDBox position="relative" mb={2}>
                <Card sx={{ position: "relative", mt: 0, mx: 3, py: 2, px: 2 }}>
                    <MDBox mt={3} mb={3}>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize"
                                      sx={{ paddingLeft: 3 }}>
                            My Activity
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={3}>
                        <DataTable
                            table={{ columns, rows }}
                            isSorted={true}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </MDBox>
                </Card>
            
            </MDBox>
            
            <Footer />
        </DashboardLayout>
    );
}

export default Overview;
