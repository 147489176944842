// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import approvalTableData from "./data/ApprovalRequestTableData";
import DataTable from "../../components/Tables/DataTable";
import MDTypography from "../../components/MDTypography";
import authAPI from "../../server/auth";
import { useAuthHeader } from "react-auth-kit";
import { useState } from "react";

function Tables() {
    const authHeader = useAuthHeader();
    const [load, setLoad] = useState(0);
    
    function handleApproval(req) {
        
        authAPI.approveAccessRequest(authHeader(), req).then(r => {
            if (r.success) {
                alert("Request Approved");
                setLoad(current => current+1)
            } else {
                alert("not approved");
            }
        });
    }
    
    const { columns, rows } = approvalTableData(handleApproval);
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <MDBox mx={1} py={2} px={2}>
                                <MDTypography variant="h6" color="black">
                                    Access Requests
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            
            <Footer />
        </DashboardLayout>
    );
}

export default Tables;
