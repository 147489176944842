import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import MDSnackbar from "../../components/MDSnackbar";
import Avatar from "@mui/material/Avatar";
import MDInput from "../../components/MDInput";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import influencersAPI from "../../server/influencers";
import { useAuthHeader } from "react-auth-kit";
import MDButton from "../../components/MDButton";
import config from "../../config";
import auth from "../../server/auth";


function SingleInfluencer() {
    const { state } = useLocation();
    const [isEditing, setEditing] = useState(false);
    const authHeader = useAuthHeader();
    
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const [successSB, setSuccessSB] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    
    
    const [platforms, setPlatforms] = useState(null);
    const [influencer, setInfluencer] = useState({});
    
    useEffect(() => {
        if (state && state.isEditing) {
            setEditing(true);
            let b = state.influencer;
            console.log(b);
            setInfluencer(b);
        }
        !platforms && influencersAPI.getPlatforms(authHeader()).then(data => {
            if (data.success) {
                setPlatforms(data.data);
            }
        });
    }, []);
    
    const handleUpdateData = () => {
        if (Object.keys(influencer).length < 8){
            setErrMessage("Please fill out all fields.")
            openErrorSB()
        }else{
            if (isEditing) {
                influencersAPI.update(authHeader(), influencer).then(r => {
                    if (r.success){
                        setSuccessMessage("Influencer Updated Successfully!")
                        openSuccessSB()
                    }else{
                        setErrMessage("Error Updating Data. Please try again.")
                        openErrorSB()
                    }
                })
            } else {
                influencersAPI.add(authHeader(), influencer).then(r => {
                    if (r.success){
                        setSuccessMessage("Influencer Added Successfully!")
                        openSuccessSB()
                    }else{
                        setErrMessage("Error Adding Data. Please try again.")
                        openErrorSB()
                    }
                })
            }
        }
        
        
    };
    
    
    function updateState(key, value) {
        setInfluencer(current => {
            let newobj = Object.assign({}, current);
            newobj[key] = value;
            return newobj;
        });
    }
    
    function matchPlatform(key) {
        if (influencer.platform && platforms) {
            console.log(key);
            let obj = platforms.find(o => o.name === key);
            return config.serverURL + obj.location;
        }
    }
    
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={errMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
            dateTime={""} />
    );
    const renderSuccessSB = (
        <MDSnackbar
            color="success"
            icon="check"
            title="Success"
            content={successMessage}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
            dateTime={""} />
    );
    return (
        <DashboardLayout>
            {renderErrorSB}
            {renderSuccessSB}
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox py={3} px={2}>
                                <MDTypography variant="h6" color="dark">
                                    {isEditing ? "Influencer" : "Create New Influencer"}
                                </MDTypography>
                            </MDBox>
                        </Card>
                    </Grid>
                    
                    <Grid item xs={12} md={5}>
                        <Card>
                            <MDBox py={3} px={2}>
                                <MDTypography variant="h6" color="dark">
                                    Influencer Details
                                </MDTypography>
                            </MDBox>
                            <MDBox py={1} px={2}>
                                <Grid container flexWrap={"nowrap"}>
                                    <Grid item m={1} width={"50%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Title"}
                                                 value={influencer.title}
                                                 onChange={e => {
                                                     updateState("title", e.target.value);
                                                 }} />
                                    </Grid>
                                    <Grid item m={1} width={"50%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Sub Title"}
                                                 onChange={e => {
                                                     updateState("subtitle", e.target.value);
                                                 }}
                                                 value={influencer.subtitle} />
                                    </Grid>
                                </Grid>
                                
                                <Grid container flexWrap={"wrap"}>
                                    <Grid item m={1} width={"100%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Image URL"}
                                                 onChange={e => {
                                                     updateState("imagePath", e.target.value);
                                                 }}
                                                 value={influencer.imagePath} />
                                    </Grid>
                                    <Grid item m={1} width={"100%"}>
                                        <MDInput type="textarea" multiline fullWidth size={"small"}
                                                 label={"Video Embed HTML"}
                                                 onChange={e => {
                                                     updateState("videoURL", e.target.value);
                                                 }}
                                                 value={influencer.videoURL} />
                                    
                                    </Grid>
                                    <Grid item width={"100%"} m={1}>
                                        <FormControl fullWidth size={"small"}>
                                            <InputLabel id="demo-simple-select-label">Platform</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={influencer.platform ? influencer.platform : ""}
                                                label="Platform"
                                                onChange={e => {
                                                    updateState("platform", e.target.value);
                                                }}
                                                sx={{ height: "40px" }}
                                            >
                                                {platforms && platforms.map((p, key) => (
                                                    <MenuItem value={p.name} key={key}>{p.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container flexWrap={"nowrap"}>
                                    <Grid item m={1} width={"33%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Followers"}
                                                 onChange={e => {
                                                     updateState("followers", e.target.value);
                                                 }}
                                                 value={influencer.followers} />
                                    </Grid>
                                    <Grid item m={1} width={"33%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Deposit $"}
                                                 onChange={e => {
                                                     updateState("deposits", e.target.value);
                                                 }}
                                                 value={influencer.deposits} />
                                    </Grid>
                                    <Grid item m={1} width={"33%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Price $"}
                                                 onChange={e => {
                                                     updateState("price", e.target.value);
                                                 }}
                                                 value={influencer.price} />
                                    </Grid>
                                </Grid>
                                <MDBox py={2} m={1} textAlign={"end"}>
                                    <MDButton color={"info"} onClick={handleUpdateData}>
                                        {isEditing ? "Update Influencer" : "Add Influencer Details"}
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                    
                    
                    {/* CARD VIEW */}
                    <Grid item xs={12} md={7}>
                        {influencer && Object.keys(influencer).length !== 0 &&
                            <Card>
                                <MDBox py={3} px={2}>
                                    <Grid container pb={2} mb={2} borderBottom={1}
                                          borderColor={"#eaeaea"}>
                                        
                                        {/* Image container */}
                                        <Grid item width={"120px"} display={"flex"} alignItems={"center"}
                                              justifyContent={"center"}>
                                            <MDBox position={"relative"}>
                                                <Avatar alt="avatar" src={influencer.imagePath}
                                                        sx={{ width: 80, height: 80, zIndex: 1 }}
                                                        position={"relative"}
                                                />
                                                
                                                <MDBox mt={-3} display={"flex"} alignItems={"center"}
                                                       justifyContent={"center"}>
                                                    <Avatar alt="avatar"
                                                            src={matchPlatform(influencer.platform)}
                                                            bgcolor={"dark"}
                                                            sx={{ width: 30, height: 30, zIndex: 2 }}
                                                            position={"relative"} />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        
                                        {/* Title container */}
                                        <Grid item display={"flex"} alignItems={"center"}
                                              justifyContent={"center"}>
                                            <MDBox position={"relative"} sx={{ paddingLeft: 2 }}>
                                                <MDTypography color={"dark"} fontWeight={"bold"} variant={"h4"}>
                                                    {influencer.title}
                                                </MDTypography>
                                                <MDTypography color={"dark"} fontWeight={"regular"}>
                                                    {influencer.subtitle}
                                                </MDTypography>
                                            </MDBox>
                                        </Grid>
                                    
                                    </Grid>
                                    
                                    <Grid container>
                                        <Grid item width={"40%"} px={3}>
                                            <MDTypography color={"dark"} fontWeight={"regular"} variant={"caption"}>
                                                Followers</MDTypography>
                                            <MDTypography color={"dark"} fontWeight={"bold"} variant={"h4"} mb={0}>
                                                {influencer.followers}
                                            </MDTypography>
                                            
                                            <MDTypography color={"dark"} fontWeight={"regular"} variant={"caption"}>
                                                Deposits</MDTypography>
                                            <MDTypography color={"dark"} fontWeight={"bold"} variant={"h4"}>
                                                $ {influencer.deposits}
                                            </MDTypography>
                                            
                                            <MDTypography color={"dark"} fontWeight={"regular"} variant={"caption"}>
                                                Price</MDTypography>
                                            <MDTypography color={"dark"} fontWeight={"bold"} variant={"h4"}>
                                                $ {influencer.price}
                                            </MDTypography>
                                        </Grid>
                                        <Grid item width={"60%"}>
                                            <style>
                                                {`.iframe-wrapper iframe{height: 100%;width: 100%;border-radius: 8px;}`}
                                            </style>
                                            <MDBox bgColor={"#f1f1f1"} borderRadius={"4px"} height={"200px"}
                                                   width={"100%"} className={"iframe-wrapper"}
                                                   dangerouslySetInnerHTML={{ __html: influencer.videoURL }}>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                
                                
                                </MDBox>
                            </Card>
                        }
                    
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default SingleInfluencer;
