const axios = require("axios");
import config from "../config";
const baseURL = config.apiBaseURL;


const influencersAPI = {
    listAll: (token) => {
        const config = {
            method: "get",
            url: `${baseURL}/influencers/list`,
            headers: {
                "Authorization": token
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    getPlatforms: (token) => {
        const config = {
            method: "get",
            url: `${baseURL}/influencers/get-platforms`,
            headers: {
                "Authorization": token
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    add: (token, data) => {
        const config = {
            method: "post",
            url: `${baseURL}/influencers/add-one`,
            headers: { "Authorization": token },
            data: data
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    delete: (token, data) => {
        const config = {
            method: "post",
            url: `${baseURL}/influencers/delete-one`,
            headers: { "Authorization": token },
            data: data,
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    update: (token, data) => {
        const config = {
            method: "post",
            url: `${baseURL}/influencers/update-one`,
            headers: { "Authorization": token },
            data: data
            
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    }
};

export default influencersAPI;