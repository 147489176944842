import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import MDSnackbar from "../../components/MDSnackbar";
import MDInput from "../../components/MDInput";

import { useAuthHeader } from "react-auth-kit";
import MDButton from "../../components/MDButton";
import config from "../../config";
import mediaAPI from "../../server/media";


function SingleMedia() {
    const { state } = useLocation();
    const [isEditing, setEditing] = useState(false);
    const authHeader = useAuthHeader();
    
    
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const [successSB, setSuccessSB] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    
    const [platform, setPlatform] = useState(null);
    const [price, setPrice] = useState(null);
    const [traffic, setTraffic] = useState(null);
    const [title, setTitle] = useState(null);
    const [country, setCountry] = useState(null);
    const [logoImage, setLogoImage] = useState(null);
    const [mediaImage, setMediaImage] = useState(null);
    
    const [logoPreview, setLogoPreview] = useState(null);
    const [mediaPreview, setMediaPreview] = useState(null);
    const [id, setId] = useState(null);
    
    useEffect(() => {
        if (state && state.isEditing) {
            setEditing(true);
            let b = state.media;
            
            console.log(b);
            
            setTraffic(b.traffic);
            setPlatform(b.platform);
            setPrice(b.price);
            setTitle(b.title);
            setCountry(b.country);
            setId(b._id);
            setLogoPreview(config.serverURL + "/" + b.logoPath);
            setMediaPreview(config.serverURL + "/" + b.imagePath);
        }
        
    }, []);
    
    const handleUpdateData = () => {
        if (isEditing) {
            if (id){
                let fd = { platform, price, traffic, title, id, country }
                
                mediaAPI.update(authHeader(), fd).then(r => {
                    if (r.success){
                        setSuccessMessage("Media Updated Successfully!")
                        openSuccessSB()
                    }
        
                })
            }else{
                setErrMessage("No ID available to update. " +
                    "Please try reloading the page or go back on the main page, then come back.")
                openErrorSB()
            }
            
        } else {
            
            let fd = new FormData();
            fd.append("logo", logoImage);
            fd.append("media", mediaImage);
            fd.append("platform", platform);
            fd.append("price", price);
            fd.append("traffic", traffic);
            fd.append("title", title);
            fd.append("country", country);
            
            mediaAPI.add(authHeader(), fd).then(r => {
                console.log(r);
                if (r.success){
                    setSuccessMessage("Media Added Successfully!")
                    openSuccessSB()
                }else{
                    setErrMessage(r.err)
                    openErrorSB()
                }
            });
        }
    };
    
    
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={errMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
            dateTime={""} />
    );
    const renderSuccessSB = (
        <MDSnackbar
            color="success"
            icon="check"
            title="Success"
            content={successMessage}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
            dateTime={""} />
    );
    
    return (
        <DashboardLayout>
            {renderErrorSB}
            {renderSuccessSB}
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox py={3} px={2}>
                                <MDTypography variant="h6" color="dark">
                                    {isEditing ? "Media" : "Create New Media Post"}
                                </MDTypography>
                            </MDBox>
                        </Card>
                    </Grid>
                    
                    <Grid item xs={12} md={5}>
                        <Card>
                            <MDBox py={3} px={2}>
                                <MDTypography variant="h6" color="dark">
                                    Media Details
                                </MDTypography>
                            </MDBox>
                            <MDBox py={1} px={2}>
    
                                {!isEditing &&
                                    <Grid container flexWrap={"nowrap"}>
                                        <Grid item m={1} width={"50%"}>
                                            <MDTypography color={"dark"} fontWeight={"regular"} variant={"caption"}>
                                                Attach logo image</MDTypography>
                                            <MDInput type="file" fullWidth size={"small"}
                                                     inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                                                     onChange={e => {
                                                         setLogoImage(e.target.files[0]);
                                                         setLogoPreview(URL.createObjectURL(e.target.files[0]));
                                                     }} />
                                        </Grid>
                                        <Grid item m={1} width={"50%"}>
                                            <MDTypography color={"dark"} fontWeight={"regular"} variant={"caption"}>
                                                Attach media image</MDTypography>
                                            <MDInput type="file" fullWidth size={"small"}
                                                     inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                                                     onChange={e => {
                                                         setMediaImage(e.target.files[0]);
                                                         setMediaPreview(URL.createObjectURL(e.target.files[0]));
                                                     }} />
                                        </Grid>
                                    </Grid>
                                }
                                
                                
                                <Grid container flexWrap={"nowrap"}>
                                    <Grid item m={1} width={"100%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Platform"}
                                                 value={platform}
                                                 onChange={e => {
                                                     setPlatform(e.target.value);
                                                 }} />
                                    </Grid>
                                </Grid>
                                
                                
                                <Grid container flexWrap={"nowrap"}>
                                    <Grid item m={1} width={"50%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Title"}
                                                 onChange={e => {
                                                     setTitle(e.target.value);
                                                 }}
                                                 value={title} />
                                    </Grid>
                                    <Grid item m={1} width={"50%"}>
                                        <MDInput type="text" fullWidth size={"small"} label={"Country Flag Emoji"}
                                                 onChange={e => {
                                                     setCountry(e.target.value);
                                                 }}
                                                 value={country} />
                                    </Grid>
                                </Grid>
                                
                                <Grid container flexWrap={"nowrap"}>
                                    <Grid item m={1} width={"50%"}>
                                        <MDInput type="number" fullWidth size={"small"} label={"Price"}
                                                 onChange={e => {
                                                     setPrice(e.target.value);
                                                 }}
                                                 value={price} />
                                    </Grid>
                                    <Grid item m={1} width={"50%"}>
                                        <MDInput type="number" fullWidth size={"small"} label={"Traffic"}
                                                 onChange={e => {
                                                     setTraffic(e.target.value);
                                                 }}
                                                 value={traffic} />
                                    </Grid>
                                </Grid>
                                
                                <MDBox py={2} m={1} textAlign={"end"}>
                                    <MDButton color={"info"} onClick={handleUpdateData}>
                                        {isEditing ? "Update media" : "Add media Details"}
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                    
                    
                    {/* CARD VIEW */}
                    <Grid item xs={12} md={7}>
                        
                        <Card>
                            <MDBox py={3} px={2}>
                                <Grid container>
                                    <Grid item width={"40%"} px={3} position={"relative"}>
                                        <Grid item width={"100%"}>
                                            <br />
                                            <img src={logoPreview && logoPreview} alt=""
                                                 style={{ height: 80, width: "100%", objectFit: "cover" }} />
                                        </Grid>
                                        <br />
                                        
                                        <MDBox position={"absolute"} sx={{ bottom: 0 }} width={"100%"}>
                                            <MDTypography color={"dark"} fontWeight={"regular"} variant={"caption"}>
                                                Traffic</MDTypography>
                                            <MDTypography color={"dark"} fontWeight={"bold"} variant={"h4"} mb={0}>
                                                {traffic}
                                            </MDTypography>
                                            
                                            <MDTypography color={"dark"} fontWeight={"regular"} variant={"caption"}>
                                                Price</MDTypography>
                                            <MDTypography color={"dark"} fontWeight={"bold"} variant={"h4"}>
                                                $ {price}
                                            </MDTypography>
                                        </MDBox>
                                    
                                    </Grid>
                                    <Grid item width={"60%"}>
                                        <Grid container flex justifyContent={"space-between"} px={2.4}>
                                            <Grid item>
                                                <MDTypography color={"dark"}
                                                              sx={{ fontSize: 14 }}
                                                              fontWeight={"regular"} variant={"h5"}>
                                                    {title}
                                                </MDTypography>
                                            </Grid>
                                            <Grid item>
                                                <MDTypography color={"dark"} fontWeight={"regular"}
                                                              sx={{ fontSize: 14 }}
                                                              variat={"h5"}>{country}</MDTypography>
                                            
                                            </Grid>
                                        </Grid>
                                        <MDBox borderRadius={"4px"} textAlign={"center"} sx={{ minHeight: 300 }}
                                               width={"100%"} className={"iframe-wrapper"}>
                                            <img src={mediaPreview && mediaPreview} alt=""
                                                 style={{ width: "90%", height: 300, objectFit: "cover" }} />
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            
                            
                            </MDBox>
                        </Card>
                    
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default SingleMedia;
