// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

// Data
import authorsTableData from "./data/influencersTableData";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

function Tables() {
    const { columns, rows } = authorsTableData();
    const navigate = useNavigate();
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                py={3}
                                px={2}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <MDTypography variant="h6" color="dark">
                                    Influencers
                                </MDTypography>
                                
                                <MDButton color={"info"} onClick={()=>{ navigate(`/influencers/create`)}}>
                                    Create New
                                </MDButton>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Tables;
