const axios = require("axios");
import config from "../config";
const baseURL = config.apiBaseURL;


const usersAPI = {
    listAll: (token) => {
        const config = {
            method: "get",
            url: `${baseURL}/users/list`,
            headers: {
                "Authorization": token
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    changeAccess: (token, data) => {
        const config = {
            method: "post",
            url: `${baseURL}/users/change-profile-access`,
            headers: {
                "Authorization": token
            },
            data: data
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
    }
};

export default usersAPI;
