const axios = require("axios");
import config from "../config";
const baseURL = config.apiBaseURL;


const activityAPI = {
    listAll: (token) => {
        const config = {
            method: "get",
            url: `${baseURL}/activity/list`,
            headers: {
                "Authorization": token
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    },
    listMine: (token) => {
        const config = {
            method: "get",
            url: `${baseURL}/activity/my-activity`,
            headers: {
                "Authorization": token
            }
        };
        return axios(config).then(function(response) {
            return response.data;
        }).catch(function(error) {
            return (error);
        });
        
    }
};

export default activityAPI;