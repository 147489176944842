// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

import tableData from "./articlesTableData";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MDSnackbar from "components/MDSnackbar";


function Tables() {
    const { columns, rows } = tableData();
    const navigate = useNavigate();
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const [errorSB, setErrorSB] = useState(false);
    const [newArticleTitle, setNewArticleTitle] = useState("");
    const [errMessage, setErrMessage] = useState("");
    
    let handleNewPostLink = (e) => {
        if (newArticleTitle !== "") {
            const slug = newArticleTitle.replaceAll(" ", "-").toLowerCase()
            
            navigate(`/articles/create-new/${slug}`, {state: {title: newArticleTitle, slug: slug}});
        } else {
            setErrMessage("Please enter new post's title")
            setErrorSB(true)
        }
    };
    
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={errMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
            dateTime={""} />
    );
    
    return (
        <DashboardLayout>
            {renderErrorSB}
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            {/* <MDBox py={3} px={4}> */}
                            {/*     <MDTypography variant="h6" color="black"> */}
                            {/*         Quick Compose Blog Post */}
                            {/*     </MDTypography> */}
                            {/* </MDBox> */}
                            <MDBox py={3} px={4}>
                                <Grid container>
                                    <Grid item p={2} width={"40%"} display={"flex"} alignItems={"center"}>
                                        <MDTypography variant="h6" color="black" sx={{ margin: 0 }}>
                                            Quick Compose Blog Post
                                        </MDTypography>
                                    </Grid>
                                    <Grid item p={2} width={"40%"}>
                                        <MDInput type="text" label="New post title" fullWidth
                                                 onChange={e => setNewArticleTitle(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item p={2}>
                                        <MDButton color={"dark"} variant={"gradient"} onClick={handleNewPostLink}>Compose
                                            New</MDButton>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                py={3}
                                px={4}
                            >
                                <MDTypography variant="h6" color="black">
                                    Articles on site
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Tables;
