import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import { useEffect, useState } from "react";
import usersAPI from "../../server/users";
import { useAuthHeader } from "react-auth-kit";
import config from "../../config";

export default function data({ setDetail, handleClickOpen }) {
    
    const [users, setUsers] = useState(null);
    const authHeader = useAuthHeader();
    
    useEffect(() => {
        !users &&
        usersAPI.listAll(authHeader()).then(res => {
            console.log(res);
            if (res.success === true) {
                setUsers(res.data);
            } else {
                alert(res);
            }
        });
    }, [users]);
    
    const Author = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );
    
    const Job = ({ description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
    );
    
    function parseRows() {
        let rows = [];
        users && users.forEach(user => {
            let r = {
                full_name: <Author image={user.image ? config.userImagePath + user.image : ""}
                                   name={user.firstname + " " + user.surname}
                                   email={user.email} />,
                
                nick_name: <Job description={user.nickname} />,
                
                position: (
                    <MDBox ml={-1}>
                        <MDBadge badgeContent={user.access_level}
                                 color={user.access_level === "manager" ? "success" : "secondary"}
                                 variant="gradient" size="sm" />
                    </MDBox>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption"
                                  color="text" fontWeight="medium"
                                  onClick={() => {
                                      setDetail(user);
                                      handleClickOpen()
                                  }}>
                        View Details
                    </MDTypography>
                )
            };
            rows.push(r);
        });
        
        return rows;
    }
    
    
    return {
        columns: [
            { Header: "Full Name", accessor: "full_name", align: "left" },
            { Header: "Nick Name", accessor: "nick_name", align: "left" },
            { Header: "Position", accessor: "position", align: "left" },
            { Header: "action", accessor: "action", align: "center" }
        ],
        rows: parseRows()
    };
}
