// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Images
import { useContext, useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import authAPI from "../../../server/auth";


export default function data(action) {
    const [data, setData] = useState(null);
    const authHeader = useAuthHeader();
    
    const navigate = useNavigate();
    
    useEffect(() => {
        !data &&
        authAPI.getRequests(authHeader()).then(res => {
            console.log(res);
            if (res.success === true) {
                setData(res.data);
                console.log(res.data);
            } else {
                alert(res);
            }
        });
    }, [data]);
    
    const User = ({ name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );
    
    const getRows = () => {
        let rows = [];
        data && data.forEach(value => {
            let row = {
                user: <User name={value.applicant.firstname + " " + value.applicant.surname}
                            email={value.applicant.email} />,
                pages: (
                    <MDBox ml={-1}>
                        {value.requestedPages.map((p, key) => {
                            if (p === "01") {
                                return <MDBadge key={key}
                                                badgeContent="Influencers" color="info" variant="gradient" size="sm" />;
                            }
                            if (p === "02") {
                                return <MDBadge key={key}
                                                badgeContent="Media" color="info" variant="gradient" size="sm" />;
                            }
                            if (p === "03") {
                                return <MDBadge key={key}
                                                badgeContent="Articles" color="info" variant="gradient" size="sm" />;
                            }
                            
                        })}
                    
                    </MDBox>
                ),
                action: (
                    <MDTypography component="a" href={"#"}
                                  onClick={()=>{
                                      action(value)
                                  }}
                                  variant="gradient" color="text" fontWeight="medium">
                        Approve Request
                    </MDTypography>
                )
            };
            rows.push(row);
        });
        
        return rows;
    };
    
    return {
        columns: [
            { Header: "user", accessor: "user", width: "45%", align: "left" },
            { Header: "pages", accessor: "pages", align: "center" },
            { Header: "action", accessor: "action", align: "center" }
        ],
        
        rows: getRows()
    };
}
