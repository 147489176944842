// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import BasicLayout from "../components/BasicLayout";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDSnackbar from "../../../components/MDSnackbar";
import authAPI from "../../../server/auth";

function Cover() {
    const navigate = useNavigate()
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    
    const key = searchParams.get("key");
    
    const [isKey, setIsKey] = useState(false);
    
    useEffect(() => {
        if (key && key.startsWith("s_n_p_")) {
            setIsKey(true);
            
        } else {
            // redirect to home
            navigate("/")
        }
    }, [key]);
    
    
    const handleClick = (e) => {
        e.preventDefault();
    
        
        if (password !== "" && password2 !== "" && password2 === password) {
            authAPI.setNewPasswordAfterValidatingKey(key, password).then(r => {
                setErrMessage(r.message)
                if (r.success){
                    navigate("/")
                    openErrorSB()
                }else{
                    openErrorSB()
                }
                
            });
        }else{
            setErrMessage("Passwords does not match.")
            openErrorSB()
        }
        
    };
    
    
    const renderErrorSB = (
        <MDSnackbar
            color="warning"
            icon="warning"
            title={errMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
            dateTime={""} />
    );
    
    
    return (
        <BasicLayout image={bgImage}>'
            {renderErrorSB}
            
            <Grid item xs={11} sm={9} md={6} lg={6} xl={4} my={10}>
                <MDBox mt={10}>
                    {isKey &&
                        <Card>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="success"
                                mx={2}
                                mt={-3}
                                py={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                                    Create Password
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form" onSubmit={handleClick}>
                                    <MDBox mb={4}>
                                        <MDInput type="password" label="Password" variant="standard" fullWidth
                                                 onChange={e => {
                                                     setPassword(e.target.value);
                                                 }} required
                                        />
                                    </MDBox>
                                    <MDBox mb={4}>
                                        <MDInput type="password" label="Confirm Password" variant="standard" fullWidth
                                                 onChange={e => {
                                                     setPassword2(e.target.value);
                                                 }} required
                                        />
                                    </MDBox>
                                    <MDBox mt={6} mb={1}>
                                        <MDButton variant="gradient" color="info" fullWidth type={"submit"}>
                                            Create
                                        </MDButton>
                                    </MDBox>
    
                                    <MDBox mt={3} mb={1} textAlign="center">
                                        <MDTypography variant="button" color="text">
                                            Need to login?{" "}
                                            <MDTypography
                                                component={Link}
                                                to="/authentication/sign-in"
                                                variant="button"
                                                color="info"
                                                fontWeight="medium"
                                                textGradient
                                            >
                                                Sign In
                                            </MDTypography>
                                        </MDTypography>
                                    </MDBox>
                                    
                                </MDBox>
                            </MDBox>
                        </Card>
                    }
                    
                </MDBox>
            </Grid>
        
        
        </BasicLayout>
    );
}

export default Cover;
