import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "components/Sidenav";
import theme from "assets/theme";
import routes from "routes";
import { setMiniSidenav, useMaterialUIController } from "context";


import { RequireAuth, useAuthUser, useIsAuthenticated } from "react-auth-kit";

export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, layout, sidenavColor, darkMode } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();
    
    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };
    
    // // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };
    
    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);
    
    const user = useAuthUser();
    const isAuthenticated = useIsAuthenticated();
    
    
    const getPagesAccordingToAccess = (routes) => {
        // console.log("Getting pages");
        // console.log(isAuthenticated());
        // console.log(user());
        
        if (isAuthenticated()) {
            let pos = user().access_level;
            let allowed = user().pagesallowed;
            if (pos === "manager") {
                return routes;
            }
            const guarededRoutes = [];
            routes.forEach(route => {
                if (route.route) {
                    allowed.forEach(entry => {
                        if (entry === route.code) {
                            guarededRoutes.push(route);
                        }
                        
                    });
                    if (route.code === "-1") {
                        guarededRoutes.push(route);
                    }
                }
            });
            return guarededRoutes;
            
        } else {
            return routes;
        }
        
    };
    
    
    const getRoutes = (allRoutes) => allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }
        if (route.route) {
            if (route.secure) {
                return (
                    <Route exact path={route.route} element={
                        <RequireAuth loginPath={"/authentication/sign-in"}>
                            {route.component}
                        </RequireAuth>
                    } key={route.key} />
                );
                
                
            } else {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }
        }
        
        
        return null;
    });
    
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {layout === "dashboard" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brandName="UpTrend Dashboard"
                            routes={getPagesAccordingToAccess(routes)}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                    
                    </>
                )}
                <Routes>
                    {getRoutes(getPagesAccordingToAccess(routes))}
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                </Routes>
            </ThemeProvider>
        </>
    );
}
