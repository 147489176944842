import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import BasicLayout from "../components/BasicLayout";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import MDSnackbar from "../../../components/MDSnackbar";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import usersAPI from "../../../server/users";
import authAPI from "../../../server/auth";


function Cover() {
    const [dpFileName, setDpFileName] = useState("");
    const [confirmPasswordError, setPasswordError] = useState(false);
    const [isManager, setManager] = useState(true)
    
    const [errorSB, setErrorSB] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [successMessage, seTsuccessMessage] = useState("");
    const [successSB, setSuccessSB] = useState(false)
    
    const navigate = useNavigate()
    
    
    function handleFileSetting(e) {
        if (!e.target.files) {
            alert("no file selected");
            return;
        }
        const file = e.target.files[0];
        const { name } = file;
        setDpFileName(name);
    }
    
    const handleformSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (formData.get("password") !== formData.get("confirm-password")) {
            setPasswordError(true);
            setErrMessage("Passwords does not match");
            openSB();
            
        }else{
            authAPI.register(formData).then(r =>{
                if (r.success){
                    seTsuccessMessage("Account created successfully. Redirecting to login!")
                    openSuccessSB()
                    setTimeout(()=>{navigate("/authentication/login")}, 2000)
                    
                }else{
                    setErrMessage("Failed to create account. Try with a different email address.")
                    openSB()
                }
            })
        }
        
        
        
    };
    
    
    const openSB = () => setErrorSB(true);
    const closeSB = () => setErrorSB(false);
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={errMessage}
            open={errorSB}
            onClose={closeSB}
            close={closeSB}
            bgWhite
            dateTime={""} />
    );
    
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <MDSnackbar
            color="success"
            icon="check"
            title="Success"
            content={successMessage}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
            dateTime={""} />
    );
    
    return (
        <BasicLayout image={bgImage}>
            {renderErrorSB}
            {renderSuccessSB}
            <Grid item xs={11} sm={9} md={6} lg={6} xl={5} my={10}>
                <Card>
                    <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success"
                           mx={2} mt={-3} p={3} mb={1} textAlign="center">
                        <MDTypography variant="h4" fontWeight="medium" color="white" my={1}>
                            Register on Uptrend
                        </MDTypography>
                    </MDBox>
                    
                    <MDBox pt={4} pb={3} px={3}>
                        <MDBox component="form" role="form" onSubmit={handleformSubmit}>
                            <Grid container alignItems={"center"}>
                                <Grid item width={"50%"} p={2}>
                                    <MDBox mb={2}>
                                        <MDInput type="text"
                                                 label="First Name"
                                                 variant="standard"
                                                 name={"firstname"}
                                                 required
                                                 fullWidth />
                                    </MDBox>
                                </Grid>
                                <Grid item width={"50%"} p={2}>
                                    <MDBox mb={2}>
                                        <MDInput type="text" label="Last Name"
                                                 name={"surname"}
                                                 required
                                                 variant="standard" fullWidth />
                                    </MDBox>
                                </Grid>
                            </Grid>
                            
                            <Grid container alignItems={"center"}>
                                <Grid item width={"50%"} p={2}>
                                    <MDBox mb={2}>
                                        <MDInput type="email"
                                                 name={"email"}
                                                 required
                                                 label="Email" variant="standard" fullWidth />
                                    </MDBox>
                                </Grid>
                                <Grid item width={"50%"} p={2}>
                                    <MDBox mb={2}>
                                        <MDInput type="text"
                                                 name={"nickname"}
                                                 required
                                                 label="Nick Name" variant="standard" fullWidth />
                                    </MDBox>
                                </Grid>
                            </Grid>
                            
                            <Grid container alignItems={"center"}>
                                <Grid item width={"50%"} p={2}>
                                    <MDBox mb={2}>
                                        <MDInput type="password"
                                                 name={"password"}
                                                 required
                                                 label="Password" variant="standard" fullWidth />
                                    </MDBox>
                                </Grid>
                                <Grid item width={"50%"} p={2}>
                                    <MDBox mb={2}>
                                        <MDInput type="password"
                                                 name={"confirm-password"}
                                                 required
                                                 error={confirmPasswordError}
                                                 onFocus={() => {
                                                     setPasswordError(false);
                                                 }}
                                                 label="Confirm Password" variant="standard"
                                                 fullWidth />
                                    </MDBox>
                                </Grid>
                            </Grid>
                            <MDBox mb={2}>
                                <MDButton component="label"
                                          variant="outlined" color="secondary" fullWidth>
                                    {dpFileName === "" ? "Upload Profile Picture" : dpFileName}
                                    <input type="file" name={"image"} hidden onChange={handleFileSetting} />
                                </MDButton>
                            </MDBox>
                            <Grid container>
                                <Grid item width={"50%"} p={2}>
                                    <MDBox mb={1}>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="manager"
                                            name="access_level"
                                            onChange={e => {
                                                if (e.target.value === "manager"){
                                                    setManager(true)
                                                }else{
                                                    setManager(false)
                                                }
                                            }}
                                        >
                                            <FormControlLabel value="manager" control={<Radio />} label="Manager Access" />
                                            <FormControlLabel value="limited" control={<Radio />} label="Limited Access" />
                                        </RadioGroup>
                                    </MDBox>
                                </Grid>
                                {!isManager &&
                                    <Grid item width={"50%"} p={2}>
                                        <MDBox mb={1}>
                                            <Checkbox name={"pagesallowed"} value={"01"} />
                                            <MDTypography
                                                variant="button"
                                                fontWeight="regular"
                                                color="text"
                                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                            >
                                                &nbsp;&nbsp; Influencers Access
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox mb={1}>
                                            <Checkbox name={"pagesallowed"} value={"02"} />
                                            <MDTypography
                                                variant="button"
                                                fontWeight="regular"
                                                color="text"
                                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                            >
                                                &nbsp;&nbsp; Media Access
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox mb={1}>
                                            <Checkbox name={"pagesallowed"} value={"03"} />
                                            <MDTypography
                                                variant="button"
                                                fontWeight="regular"
                                                color="text"
                                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                            >
                                                &nbsp;&nbsp; Blog Access
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                }
                                
                            </Grid>
                            
                            
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" fullWidth type={"submit"}>
                                    sign up
                                </MDButton>
                            </MDBox>
                            
                            
                            <MDBox mt={3} mb={1} textAlign="center">
                                <MDTypography variant="button" color="text">
                                    Already have an account?{" "}
                                    <MDTypography
                                        component={Link}
                                        to="/authentication/sign-in"
                                        variant="button"
                                        color="info"
                                        fontWeight="medium"
                                        textGradient
                                    >
                                        Sign In
                                    </MDTypography>
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Grid>
        
        </BasicLayout>
    );
}

export default Cover;
