/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

import usersData from "./usersTableData";
import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import MDButton from "../../components/MDButton";
import MDAvatar from "../../components/MDAvatar";
import config from "../../config";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import usersAPI from "../../server/users";

function Tables() {
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();
    const [detail, setDetail] = useState(null);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {setOpen(true);};
    const handleClose = () => {setOpen(false);};
    const { columns, rows } = usersData({ setDetail, handleClickOpen });
    
    useEffect(()=>{
        detail && handleClickOpen()
        console.log(detail);
    }, [detail, setDetail])
    
    const handleFormSubmit = (e)=>{
        e.preventDefault()
        const formData = new FormData(e.target);
        const queryString = new URLSearchParams(formData).toString()
        
        if (formData.get("_id") === authUser()._id){
            alert("You can not update your own profile.")
        }else{
            console.log(queryString);
            console.log(authUser()._id);
    
            usersAPI.changeAccess(authHeader(), queryString).then(r => {
                if (r.success){
                    window.location.reload()
                }
            })
            
        }
        
        
    }
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Users on Platform
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
    
            {detail !== null &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{display: "flex", alignItems: "center"}}>
                        <MDAvatar src={config.userImagePath + detail.image} name={detail.firstname} size="sm" />
                        <div>
                            &nbsp;&nbsp;{detail.firstname + " " + detail.surname}
                            &nbsp;
                            <MDTypography variant={"caption"}>({detail.nickname})</MDTypography>
                        </div>
                    </DialogTitle>
        
                    <DialogContent>
                        <Box role={"form"} component="form" onSubmit={handleFormSubmit}>
                            <input type="text" value={detail._id} name={"_id"} hidden/>
                            <input type="text" value={detail.firstname} name={"firstname"} hidden/>
                            <input type="text" value={detail.surname} name={"surname"} hidden/>
                            <input type="text" value={detail.access_level} name={"access_level_current"} hidden/>
                            
                            
                            <DialogContentText id="alert-dialog-description" sx={{minWidth: 380}}>
                                <MDBox mb={2}>
                                    <MDTypography variant={"button"} fontWeight={"normal"}>Email Address: </MDTypography>
                                    <br/>
                                    <MDTypography variant={"button"} fontWeight={"medium"}>{detail.email}</MDTypography>
                                </MDBox>
        
                                <MDBox mb={2}>
                                    <MDTypography variant={"button"} fontWeight={"normal"}>Access Level: </MDTypography>
                                    <br/>
                                    <MDTypography variant={"button"} fontWeight={"medium"}>{detail.access_level}</MDTypography>
                                </MDBox>
        
                                {detail.access_level === "manager" &&
                                    <MDBox mb={1}>
                                        <Checkbox name={"access_level"} value={"limited"}/>
                                        <MDTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="text"
                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                        >
                                            &nbsp;&nbsp; Convert to limited user
                                        </MDTypography>
                                    </MDBox>
                                }
        
        
                                {detail.access_level === "limited" &&
                                    <MDBox mb={2}>
                                        <MDBox mb={1}>
                                            <Checkbox name={"access_level"} value={"manager"}/>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="regular"
                                                color="text"
                                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                            >
                                                &nbsp;&nbsp; Convert to manager
                                            </MDTypography>
                                        </MDBox>
                
                
                                        <MDTypography variant={"button"} fontWeight={"normal"}>Pages Allowed: </MDTypography>
                                        <MDTypography variant={"button"} fontWeight={"medium"}>
                                            <MDBox mb={1}>
                                                <Checkbox name={"pagesallowed"} value={"01"}
                                                          defaultChecked={detail.pagesallowed.includes("01")}/>
                                                <MDTypography
                                                    variant="button"
                                                    fontWeight="regular"
                                                    color="text"
                                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                >
                                                    &nbsp;&nbsp; Allow Influencers Access
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mb={1}>
                                                <Checkbox name={"pagesallowed"} value={"02"}
                                                          defaultChecked={detail.pagesallowed.includes("02")}/>
                                                <MDTypography
                                                    variant="button"
                                                    fontWeight="regular"
                                                    color="text"
                                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                >
                                                    &nbsp;&nbsp; Allow Media Access
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mb={1}>
                                                <Checkbox name={"pagesallowed"} value={"03"}
                                                          defaultChecked={detail.pagesallowed.includes("03")}/>
                                                <MDTypography
                                                    variant="button"
                                                    fontWeight="regular"
                                                    color="text"
                                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                >
                                                    &nbsp;&nbsp; Allow Articles Access
                                                </MDTypography>
                                            </MDBox>
                                        </MDTypography>
                                    </MDBox>
                                }
                            </DialogContentText>
    
                            <DialogActions>
                                <Button type={"submit"}>Update</Button>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
    
                </Dialog>
            }
            
        </DashboardLayout>
    );
}

export default Tables;
