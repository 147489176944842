import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "../../components/MDButton";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import { FormControl, FormControlLabel, InputLabel, Select, Switch } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import { Editor } from "@tinymce/tinymce-react";
import articlesAPI from "../../server/articles";
import { useAuthHeader } from "react-auth-kit";
import MDInput from "../../components/MDInput";
import config from "../../config";


function CreateNewPost() {
    const editorRef = useRef(null);
    const authHeader = useAuthHeader();
    const { state } = useLocation();
    
    
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const [errorSB, setErrorSB] = useState(false);
    
    const [errMessage, setErrMessage] = useState("");
    
    const [category, setCategory] = useState("");
    const [categoriesList, setCategoryList] = useState(null);
    const [tags, setTags] = useState("");
    const [featuredImage, setFeaturedImageState] = useState(null);
    const [featuredImageURL, setFeaturedImageURL] = useState(null);
    const [status, setStatus] = useState("unpublished");
    const [initialHTML, setInitialHTML] = useState("");
    const [pid, setPID] = useState(null);
    const [description, setDescription] = useState("");
    const [isTopStory, setTopStory] = useState(false);
    const [readingTime, setReadingTime] = useState(1);
    
    const [isEditing, setEditing] = useState(false);
    
    
    useEffect(() => {
        if (state.isEditing) {
            setEditing(true);
            let b = state.blog;
            console.log(b);
            
            setCategory(b.category);
            setTags(b.tags);
            setFeaturedImageURL(b.featuredImage);
            setStatus(b.status);
            setInitialHTML(b.html);
            setPID(b._id);
            setDescription(b.description);
            setTopStory(b.star);
            setReadingTime(b.readingTime);
        }
    }, []);
    
    
    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
    };
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };
    
    
    const renderErrorSB = (
        <MDSnackbar
            color="error"
            icon="warning"
            title="Error"
            content={errMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            bgWhite
            dateTime={""} />
    );
    
    const SaveNewPost = () => {
        if (editorRef.current) {
            let editorData = editorRef.current.getContent();
            const data = {};
            
            data.html = editorData;
            data.tags = tags;
            data.category = category;
            data.status = status;
            data.description = description;
            data.title = state.title;
            data.slug = state.slug;
            data.star = isTopStory;
            data.readingTime = readingTime;
            data.featuredImage = featuredImageURL ? featuredImageURL : "";
            
            if (isEditing) {
                data.pid = pid;
                
                articlesAPI.updatePost(authHeader(), data).then(r => {
                    console.log(r);
                    if (r.success) {
                        alert("Post Updated");
                    } else {
                        alert("Failed to create the post");
                    }
                });
                
                
            } else {
                articlesAPI.createPost(authHeader(), data).then(r => {
                    console.log(r);
                    if (r.success) {
                        alert("Post Created");
                    } else {
                        alert("Failed to create the post");
                    }
                });
            }
            
        }
    };
    
    
    useEffect(() => {
        !categoriesList &&
        articlesAPI.getCategories(authHeader()).then(res => {
            console.log(res);
            if (res.success === true) {
                setCategoryList(res.data);
                setCategory(res.data[0]._id);
            } else {
                alert(res);
            }
        });
    }, [categoriesList]);
    
    
    const imageUploadHandler = (blobInfo, progress) => {
        return new Promise((resolve, reject) => {
            
            articlesAPI.uploadImage(authHeader(), blobInfo, progress).then(r => {
                if (r.success) {
                    return resolve(r.data.path);
                } else {
                    return reject("Error Uploading the image");
                }
            });
            
        });
        
        
    };
    
    const uploadFeaturedImage = () => {
        articlesAPI.uploadFeaturedImage(authHeader(), featuredImage).then(r => {
            console.log(r);
            
            if (r.success) {
                setFeaturedImageURL(r.data.path);
            } else {
                alert("Can not upload featured image.");
            }
        });
        
    };
    const setFeaturedImage = (e) => {
        setFeaturedImageState(e.target.files[0]);
        console.log(e.target.files[0]);
    };
    
    
    return (
        <DashboardLayout>
            {renderErrorSB}
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <MDBox py={3} px={4}>
                                <Grid contanier>
                                    <Grid item>
                                        <MDInput type={"file"} fullWidth
                                                 inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                                                 onChange={setFeaturedImage}
                                        />
                                    </Grid>
                                    {featuredImageURL !== null &&
                                        <Grid item height={"180px"} p={2} mt={1} textAlign={"center"}
                                              bgcolor={"#fafafa"}>
                                            <img
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                src={featuredImageURL}
                                                alt=""
                                            />
                                        </Grid>
                                    }
                                    
                                    <Grid item>
                                        <br />
                                        <MDButton color={"dark"} variant={"gradient"} fullWidth
                                                  sx={{ width: "100%", marginRight: 4 }}
                                                  onClick={uploadFeaturedImage}>
                                            Upload
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <MDBox py={3} px={4}>
                                <Grid container>
                                    <Grid item p={2} minWidth={"100%"}>
                                        <Grid container>
                                            <Grid item width={"100%"} mb={2}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch defaultChecked={isTopStory}
                                                                onChange={(e) => {
                                                                    setTopStory(e.target.checked);
                                                                }} />
                                                    }
                                                    label="Set as Top Story"
                                                />
                                            </Grid>
                                            
                                            <Grid item width={"100%"} mb={2}>
                                                <FormControl fullWidth size={"medium"}>
                                                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={category}
                                                        label="Category"
                                                        onChange={handleChangeCategory}
                                                        sx={{ height: "40px" }}
                                                    >
                                                        {categoriesList && categoriesList.map((cat, key) => (
                                                            <MenuItem value={cat._id} key={key}>{cat.title}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item width={"100%"} mb={3}>
                                                <MDInput type="text" label="Tags" fullWidth
                                                    // inputProps={{value: tags}}
                                                         value={tags}
                                                         placeholder={"Separate tags with comma"}
                                                         onChange={e => setTags(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item width={"100%"}>
                                                <MDInput type="number" label="Reading Time" fullWidth
                                                    // inputProps={{value: tags}}
                                                         value={readingTime}
                                                         placeholder={"Set Reading Time (mins)"}
                                                         onChange={e => setReadingTime(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item p={2} minWidth={"100%"}>
                                        <Grid contanier>
                                            <Grid item>
                                                <FormControl fullWidth size={"medium"}>
                                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={status}
                                                        label="Status  "
                                                        onChange={handleStatusChange}
                                                        sx={{ height: "40px" }}
                                                    >
                                                        <MenuItem value={"unpublished"}>Unpublished</MenuItem>
                                                        <MenuItem value={"published"}>Published</MenuItem>
                                                        <MenuItem value={"trash"}>Trash</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <Grid item py={2} display={"flex"} justifyContent={"flex-end"}
                                                      width={"100%"}>
                                                    <MDButton color={"info"} variant={"gradient"} fullWidth
                                                              sx={{ width: "100%" }}
                                                              onClick={SaveNewPost}>
                                                        {isEditing ? "Update Post" : "Create Post"}
                                                    </MDButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    
                                    </Grid>
                                
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox py={3} px={4}>
                                <MDInput type="text" label="Add Article Description" fullWidth
                                         multiline rows={2}
                                         defaultValue={description}
                                         onChange={e => setDescription(e.target.value)}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox py={3} px={4}>
                                <>
                                    <Editor
                                        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                                        onInit={(evt, editor) => {
                                            return editorRef.current = editor;
                                        }}
                                        initialValue={initialHTML}
                                        init={{
                                            height: 500,
                                            menubar: true,
                                            plugins: [
                                                "advlist", "autolink", "lists", "link", "image", "charmap",
                                                "anchor", "searchreplace", "visualblocks", "code", "fullscreen",
                                                "insertdatetime", "media", "table", "preview", "help", "wordcount", "template"
                                            ],
                                            toolbar: "undo redo | blocks | " +
                                                "bold italic forecolor | alignleft aligncenter " +
                                                "alignright alignjustify | bullist numlist outdent indent | " +
                                                "removeformat | help",
                                            
                                            image_uploadtab: true,
                                            images_upload_handler: imageUploadHandler,
                                            content_css: config.serverURL + "/templates/blog.css",
                                            templates: config.serverURL + "/templates/templates.json",
                                            template_replace_values: {
                                                images: config.serverURL + "/templates/images",
                                            },
                                            template_preview_replace_values: {
                                                images: config.serverURL + "/templates/images",
                                            }
                                        }}
                                    />
                                </>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CreateNewPost;
